import React from "react"

const LandingPage = () => (
<>
    <main className="px-3">
    <h1>Zenancy.</h1>
    <h3>Coming Soon.</h3>
    <p className="lead">Property portfolio manager for everyday investors.</p>
    <p className="lead">Email contact at this domain for more information.
    </p>
    </main>

    <footer className="mt-auto mt-4 text-white-50">
    <p>Developed in Sydney, Australia.</p>
    </footer>
</>
)

export default LandingPage